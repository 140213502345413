/**
 *  Smart Sticky Element
 *
 *  Emulates css3 sticky position behavior with a twist of custom easing
 */
(function(w) {
    "use strict";

    // Class Constants (more like an alias)
    var dom = document.documentElement;

    // Class Private Vars
    var methods;
    var method;

    // Polyfill for window.scrollY
    function computeScroll() {
        var scrollY;

        if (w.pageYOffset) {
            scrollY = w.pageYOffset;
        } else {
            scrollY = dom.scrollTop - dom.clientTop;
        }

        return scrollY;
    }

    // Helper method to compute scroll limit
    function computeLimit() {
        var elm = this.element;
        var par = elm.parentElement;
        var out = par.offsetHeight - elm.offsetHeight;

        if (this.elm_self_offset > 0) {
            out -= this.elm_self_offset;
        }

        return out;
    }

    // Computes target position
    function computeTargetPos() {
        var head_height;
        var pos_target;
        var pos_limits;

        // Get scroll distance
        this.scrollY = computeScroll();

        // Get current header height
        head_height = document.getElementById("site_head_wrap").offsetHeight;

        if (this.scrollY >= this.threshold - head_height) {
            pos_target = this.scrollY - (this.threshold - head_height);
            pos_limits = computeLimit.call(this);

            pos_target = Math.min(pos_target, pos_limits);
        } else {
            pos_target = 0;
        }

        return pos_target;
    }

    // Object Constructor
    function ScrollLock(id) {
        var element = document.getElementById(id);
        var output = false;

        // Element bearing given ID exists?
        if (element) {
            this.element = element;

            this.threshold = document.getElementById(
                "site_global_wrap"
            ).offsetTop;

            if (this.element.parentElement.offsetTop > this.threshold) {
                this.threshold +=
                    this.element.parentElement.offsetTop - this.threshold;
            }

            this.elm_self_offset = 0;

            if (this.element.offsetTop > this.element.parentElement.offsetTop) {
                this.elm_self_offset = this.element.offsetTop;

                if (this.element.parentElement.offsetTop > 0) {
                    this.elm_self_offset -= this.element.parentElement.offsetTop;
                }

                this.threshold += this.elm_self_offset;
            }

            this.scrollY = computeScroll();
            this.targetPos = computeTargetPos.call(this);
            this.is_scrolling = false;

            this.element.style.top = this.targetPos + "px";

            // This will call inititation routine
            output = ScrollLock.prototype.init;
        }

        // Return either false or init
        return output !== false ? output.call(this) : output;
        //
    }

    // Gets element's "top" property in px
    function getElmPos() {
        return parseFloat(this.element.style.top);
    }

    // Animation main loop
    function onWinRaf() {
        var elm_pos = getElmPos.call(this);
        var dir;
        var pos;

        w.requestAnimationFrame(onWinRaf.bind(this));

        this.targetPos = computeTargetPos.call(this);

        // If animation is not over...
        if (elm_pos !== this.targetPos) {
            // Is it going down or up?
            dir = elm_pos < this.targetPos ? 1 : -1;
            //

            // Compute easing and resulting position then apply
            pos = elm_pos + (this.targetPos - elm_pos) * 0.08;
            pos = dir > 0 ? Math.ceil(pos) : Math.floor(pos);
            //

            elm_pos = pos;

            this.element.style.top = elm_pos + "px";
        }
    }

    // Prototype Methods
    methods = {
        // Initialization Routine
        init: function() {
            // Computes sticky element position on every frame
            w.requestAnimationFrame(onWinRaf.bind(this));

            return this;
        },
    };

    // Build Prototype
    // It's easier to configure methods accessibility this way
    Object.keys(methods).forEach(function(method_name) {
        // Get the method object
        method = methods[method_name];

        // Get the method
        if (typeof method.constructor === "function") {
            method = {definition: method || false};
        }

        // Add method to prototype
        Object.defineProperty(ScrollLock.prototype, method_name, {
            value: method.definition,
            writable: method.is_writable || false,
            enumerable: method.is_enumerable || false,
            configurable: method.is_configurable || false,
        });
    });

    // Add Constructor to w Object, it is read-only
    if (!Object.ScrollLock) {
        Object.defineProperty(w, "ScrollLock", {
            value: ScrollLock,
            writable: false,
            enumerable: true,
        });
    }
})(window);

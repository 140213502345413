$(function () {

    "use strict";

    // SLIDER HOME
    if ($('#super_home_swiper').length) {
        var id = 'super_home_swiper';
        // store last played video to put in pause when slide
        var lastPlayedVideo = '';

        // init and store all youtube players
        var youtubePlayers = {};
        if ($('#' + id + ' .swiper-slide iframe.youtube_video').length >= 1) {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(tag, firstScript);

            window.onYouTubeIframeAPIReady = function () {
                $('#' + id + ' .swiper-slide iframe.youtube_video').each(function () {
                    var videoId = $(this).attr('id');
                    var youtubePlayer = new YT.Player(videoId, {
                        // add a listener to start the slider autoplay again
                        events: {
                            onStateChange: function (evt) {
                                if (evt.data === YT.PlayerState.ENDED) {
                                    evt.target.seekTo(0);
                                    evt.target.pauseVideo();
                                    swiperHome.autoplay.start();
                                }
                            }
                        }
                    });
                    youtubePlayers[videoId] = youtubePlayer;
                });

            };
        }

        // Cas Video Vimeo
        var vimeoPlayers = {};
        if ($('#'+id+' .swiper-slide iframe.vimeo_video').length >= 1) {
            $('#'+id+' .swiper-slide iframe.vimeo_video').each(function() {
                var videoId = $(this).attr('id');
                var options = { background: true, loop: true, autoplay: true };
                var playerVimeo = new Vimeo.Player($(this), options);

                // add a listener to start the slider autoplay again
                playerVimeo.on('ended', function () {
                    swiperHome.autoplay.start();
                });
                vimeoPlayers[videoId] = playerVimeo;
            });
        }

        // init swiper
        var swiperHome = new Swiper('#' + id, {
            slidesPerView: 1,
            speed: 1500,
            autoplay: {
                delay: 3000,
            },
            navigation: {
                nextEl: '#' + id + ' .swiper-button-next',
                prevEl: '#' + id + ' .swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets'
            },
            on: {
                slideChangeTransitionStart: function () {
                    /*  when a video is displayed
                        stop the old one
                        plays the new one
                        stop the slider until the end
                        store the video as last played */
                    if ($('#' + id + ' .swiper-slide-active').find('iframe.vimeo_video').length) {
                        swiperHome.autoplay.stop();
                        $('#' + id + ' .swiper-slide-active').find('iframe.vimeo_video').each(function () {
                            var videoId = $(this).attr('id');
                            vimeoPlayers[videoId].setLoop(false); // on ended event not fire if loop = true
                            setLastVideoPause(videoId);
                            vimeoPlayers[videoId].play();
                            lastPlayedVideo = videoId;
                        });
                    } else if ($('#' + id + ' .swiper-slide-active').find('iframe.youtube_video').length) {
                        swiperHome.autoplay.stop();
                        $('#' + id + ' .swiper-slide-active').find('iframe.youtube_video').each(function () {
                            var videoId = $(this).attr('id');
                            setLastVideoPause(videoId);
                            youtubePlayers[videoId].playVideo();
                            lastPlayedVideo = videoId;
                        });
                    }
                }
            }
        });
    }

    if ($('.cover-module').length) {

        // Cas Video Vimeo
        var vimeoPlayers = {};
        if ($('.cover-module iframe.vimeo_video').length >= 1) {
            $('.cover-module iframe.vimeo_video').each(function () {
                var videoId = $(this).attr('id');
                var options = { background: true, loop: true, autoplay: true };
                var playerVimeo = new Vimeo.Player($(this), options);
                vimeoPlayers[videoId] = playerVimeo;
            });
        }
    }

    // with the stored, put the last video in pause
    function setLastVideoPause(newPlayedVideo) {
        if(lastPlayedVideo!='') {
            if($('#'+lastPlayedVideo).hasClass('vimeo_video')) {
                vimeoPlayers[lastPlayedVideo].pause();
            }
            else if($('#'+lastPlayedVideo).hasClass('youtube_video')) {
                youtubePlayers[lastPlayedVideo].pauseVideo();
            }
            lastPlayedVideo = newPlayedVideo;
        }
    }

    window.setTimeout(function () {

        $('.home-module.cover-module img[alt*="#letter_from_us"]').each(function() {

            $( "<p id='letter_from_us_1'></p>" ).insertBefore(this);

        });

        if (window.location.hash) {
            var hash = window.location.hash;

            if ($(hash).length) {
                $('html, body').animate({
                    scrollTop: $(hash).offset().top - 130
                }, 1000, 'swing');
            }
        }
    }, 100);
});

/* AUtocomplete global utilisé uniquement pour charger la liste des magasin dans le distributeur le plus proche (newsletter.php) */
var globalString;

function generateAutocomplete( type, indice ) {

	var valType;
	switch (type) {
		case 'magasin': // pour newsletterFavoriteStore obligé de faire ainsi car la vérification en PHP se base sur newsletterFavoriteStore alors que ce JS se base sur magasin :()
			valType = 'newsletterFavoriteStore';
			break;
		default:
			valType = type + '_input';
			break;
	}
	if(indice != undefined) {
		valType += indice;
	}

	var value = $('#' + valType).val();
	var min_length = $('#' + valType).data('minlength');

	if (min_length == '')
		min_length = 0;

	// Construction des parametre à passer au script
	var values = { search : value };

	// On recherche à partir du nombre de caractere definie sur le champs
	if( value.length >= min_length ) {

		$.ajax({
			'type': 'POST',
			'data': values,
			'url': path_relative_root + 'ajax_get_' + type + '.php',
			'async': false,
			'success': function(response) {
				// autoCompleteAll(response, type, value);
				( (type == 'magasin') && response == 'null' ) ? $('#autocomplete_'+type).css('display','none') : autoCompleteAll(response, type, value, indice);
			}
		});
	}
}

function autoCompleteAll(response, type, value, indice) {
	var valType = type;
	if(indice != undefined) {
		valType += indice;
	}

	globalString = $.parseJSON(response);

	$( '#autocomplete_' + valType ).html('');

	if( response != "" ) {

		lengthString = globalString.length;
		if( lengthString > 0 ) {

			for (var i = 0; i < lengthString; i++) {

				switch ( type ) {
					case 'magasin':
						globalString[i]['complet'] = globalString[i]['nom'] + ', ' + globalString[i]['ville'];
						// On sauvegarde l'adresse pour pouvoir la récupérer plus bas
						var adresse = globalString[i]['adresse'] + ', ' + globalString[i]['cp'] + ' ' + globalString[i]['ville'];
						globalString[i]['adresse'] = adresse.replace(/'/g, "\\'");
						break;
				}
			}

			for (var i = 0; i < lengthString; i++) {

				var sContent = $( '#autocomplete_' + valType ).html();
				var reValue = new RegExp("("+ value +")", "gi");

				if( reValue.test(globalString[i]['complet']) ) {

					var onclick = 'setclick(this, \''+type+'\', '+indice+');';

					switch (type) {
						case 'magasin':
							break;
					}

					sContent += '<li id="item_' + type + '_'+ i +'" onclick="'+onclick+'">' + globalString[i]['complet'].replace(reValue, '<span class="mot_rechercher">$1</span>') + '</li>';
					$( '#autocomplete_' + valType ).html(sContent);
				}
			}
		} else {

			// Cas aucun resultat. On laisse le champs libre a l'utilisateur de SAV reglera de lui même
		}
	}

	if ($( '#autocomplete_' + valType ).html() != '') {
		$( '#autocomplete_' + valType ).show();
		$( '#id_' + valType ).val('');
	} else {
		$( '#autocomplete_' + valType ).hide();
		$( '#id_' + valType ).val('');
	}
}

function setclick(el, type, indice) {
	var valType;
	switch (type) {
		case 'magasin': // pour newsletterFavoriteStore obligé de faire ainsi car la vérification en PHP se base sur newsletterFavoriteStore alors que ce JS se base sur magasin :()
			valType = 'newsletterFavoriteStore';
			break;
		default:
			valType = type + '_input';
			break;
	}
	if(indice != undefined) {
		valType += indice;
	}

	lengthString = globalString.length;

	var sMagasin = $('#'+valType).val();
	var idLigne = el.id;
	var element = $('#' + idLigne);

	var i;
	for (i = 0; i < lengthString; i++) {
		if ('item_'+type+'_'+i == idLigne) break;
	}

	if (i < lengthString){
		$('#'+valType).val(globalString[i]['complet']);
		$('#id_' + type ).val(globalString[i]['id']);
	}
	$( '#autocomplete_' + type ).hide();

}

function stringifyJSON(oObj) {
	var sObj = '{';
	var aObj = new Array();
	for (var sKey in oObj) {
		aObj.push('"' + sKey + '":"' + oObj[sKey] + '"');
	}
	sObj += aObj.join(',');
	sObj += '}'
	return sObj;
}
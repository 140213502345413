/**
 * Pour la gestion de la page faq.php
 * @author Aurélien Renault
 * @since 2019/03/08
 */

/**
 * Mise en pace des event
 */
function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_resultats li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each( array_questions, function( i, val ) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());	

                if ( match ) {
                    array_match.push(match);
                    $('.search_resultats').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_resultats').hide();
            }

        } else {
            $('.search_resultats').hide();
            $('.search_resultats li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_resultats').mousedown(function() {
        click_in_process = true;
    });

    $('.search_resultats').mouseup(function() {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function() {
        if(!click_in_process) {
            $('.search_resultats').hide();
            $('.search_resultats li').show().removeClass("hasMatch");
        }
    });
}

function researchFaq(node_id, level, question_id) {

    $('.search_resultats').hide();
    $('.search_resultats li').show().removeClass("hasMatch");

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({scrollTop: $(".faq_question_" + question_id + "").offset().top - 100}, 1000);

}


function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        //show answer
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp(
            "fast",
            function() {
                $(this).addClass("cache");
            }
        );

        $(".faq_reponse_" + question_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass(
            "active"
        );
        $(".faq_question_" + question_id).toggleClass("active");
    } else {
        var parent_id = $(".faq_theme_" + node_id).data("parent");

        // active
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");
        $(".faq_theme_" + parent_id).toggleClass("active");

        // show questions
        $(
            ".faq_questions:not(.faq_questions_" +
            node_id +
            ", .faq_questions_" +
            parent_id +
            " )"
        ).slideUp("fast", function() {
            $(this).addClass("cache");
        });

        $(".faq_questions_" + node_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        // Hide other node of same level
        $(".faq_level_" + level + ":not(.faq_theme_" + node_id + ")").each(
            function(idx) {
                var id = $(this).data("id");
                $(".faq_level_parent_" + id).slideUp("fast", function() {
                    $(".faq_level_parent_" + id)
                        .addClass("cache")
                        .trigger("classChange");
                });
            }
        );

        // Show/hide child of selected node
        $(".faq_level_parent_" + node_id).slideToggle("slow", function() {
            $(".faq_level_parent_" + node_id)
                .toggleClass("cache")
                .trigger("classChange");
        });

        $(".faq_element").on("classChange", function() {
            var id = $(this).data("id");

            if ($(this).hasClass("cache")) {
                $(".faq_level_parent_" + id)
                    .addClass("cache")
                    .trigger("classChange");
            }
        });
    }
}

$('.faq_content .w-form-line.w-submit .w-submit-button').click(function() {
    $('.faq_content .w-form-line.w-submit .w-submit-button.w-loader').addClass('loading');
    
    errorCheckFaq()
})

function errorCheckFaq() {
    $('.faq_content .w-form-line.w-submit').click(function(event) {
        const button = $(this);

        if ($('.faq_questions .w-has-error').length > 0) {
            // Temporarily prevent the button's default action if there are errors
            event.preventDefault();

            // Remove the loading class after a short delay if .w-has-error is present
            setTimeout(() => {
                $('.faq_content .w-form-line.w-submit .w-submit-button.w-loader').removeClass('loading');
            }, 200);

            // Re-enable the click event after 400ms if errors are fixed
            setTimeout(function() {
                button.off('click').on('click', function(e) {
                    if ($('.faq_questions .w-has-error').length === 0) {
                        return true; // Proceed with default behavior if no errors
                    }
                    e.preventDefault(); // Prevent again if errors still exist
                });
            }, 400);
        }
    });
}